// Данный файл - лишь собрание подключений готовых компонентов.
// Рекомендуется создавать отдельный файл в папке components и подключать все там

// Определение операционной системы на мобильных
import { mobileCheck } from "./functions/mobile-check";
console.log(mobileCheck())


let btnSearch = document.querySelector('.header__search .item__link');
var formSearch = document.querySelector('.search__form');

var toggleMenu = function toggleMenu() {
  formSearch.classList.toggle('active');
};

btnSearch.addEventListener('click', function(e) {
  e.stopPropagation();

  toggleMenu();
});

document.addEventListener('click', function (e) {
  let target = e.target;
  let its_form = target == formSearch || formSearch.contains(target);
  let its_btn = target == btnSearch;
  let form_is_active = formSearch.classList.contains('active');

  if (!its_form && !its_btn && form_is_active) {
    toggleMenu();
  }
});


import { burger } from './functions/burger';

// Определение ширины экрана
// import { isMobile, isTablet, isDesktop } from './functions/check-viewport';
// if (isDesktop()) {
//   console.log('...')
// }

// Троттлинг функции (для ресайза, ввода в инпут, скролла и т.д.)
// import { throttle } from './functions/throttle';
// let yourFunc = () => { console.log('throttle') };
// let func = throttle(yourFunc);
// window.addEventListener('resize', func);

// Фикс фулскрин-блоков
// import './functions/fix-fullheight';



// Реализация остановки скролла (не забудьте вызвать функцию)
// import { disableScroll } from './functions/disable-scroll';

// Реализация включения скролла (не забудьте вызвать функцию)
// import { enableScroll } from './functions/enable-scroll';

// Реализация модального окна
// import GraphModal from 'graph-modal';
// const modal = new GraphModal();

// Реализация табов
// import GraphTabs from 'graph-tabs';
// const tabs = new GraphTabs('tab');

// Получение высоты шапки сайта (не забудьте вызвать функцию)
// import { getHeaderHeight } from './functions/header-height';

// Подключение плагина кастом-скролла
// import 'simplebar';

// Подключение плагина для позиционирования тултипов
// import { createPopper, right} from '@popperjs/core';
// createPopper(el, tooltip, {
//   placement: 'right'
// });


import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);

const sliderMain = new Swiper('.swiper', {
  pagination: {
    el: '.swiper-pagination',
  },
  autoplay: {
    delay: 8000,
  },
});

let slideInfos = document.querySelectorAll('.slider__info .info__item')

sliderMain.on('slideChange', function (e) {
  slideInfos.forEach((item) => {
    if(item.dataset.slide == sliderMain.activeIndex) {
      slideInfos.forEach(item => item.classList.remove('active'))
      item.classList.add("active")
    }
  })

});

const search = document.querySelector('.header__wrapper');
let prevScrollPos = window.pageYOffset;


window.addEventListener('scroll', function() {
  const currentScrollPos = window.pageYOffset;
  if (prevScrollPos < 40) {
    search.classList.remove('hide');
  } else {
    search.classList.add('hide');
  }
  prevScrollPos = currentScrollPos;

  if (currentScrollPos === 30) {
    search.classList.remove('hide');
  }
});

document.querySelector('.pop__close').addEventListener('click', function() {
  document.querySelector('.popup__bg').classList.remove('active');
  document.querySelector('.pop').classList.remove('active'); })

// Получаем значение куки
var popupShown = getCookie("popupShown");

// Если куки нет, то показываем попап окно
if (!popupShown) {
  setTimeout(
    () => {
      document.querySelector('.popup__bg').classList.add('active');
      document.querySelector('.pop').classList.add('active');
      setCookie("popupShown", "true", 365);
    }, 20000
  )

}

// Функция для получения значения куки
function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) {
    return parts.pop().split(";").shift();
  }
}

// Функция для установки значения куки
function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}






// Подключение анимаций по скроллу
// import AOS from 'aos';
// AOS.init();

// Подключение параллакса блоков при скролле
// import Rellax from 'rellax';
// const rellax = new Rellax('.rellax');

// Подключение плавной прокрутки к якорям
// import SmoothScroll from 'smooth-scroll';
// const scroll = new SmoothScroll('a[href*="#"]');

// Подключение событий свайпа на мобильных
// import 'swiped-events';
// document.addEventListener('swiped', function(e) {
//   console.log(e.target);
//   console.log(e.detail);
//   console.log(e.detail.dir);
// });

// import { validateForms } from './functions/validate-forms';
// const rules1 = [...];

// const afterForm = () => {
//   console.log('Произошла отправка, тут можно писать любые действия');
// };

// validateForms('.form-1', rules1, afterForm);
